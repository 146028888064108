<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 cockpit-screen full-width gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="cockpit-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          Cockpit
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_cockpit-settings' }"
            ><icon icon="#cx-hea1-edit" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <Draggable
      v-if="activeWidgets && activeWidgets.length"
      v-bind="dragOptions"
      :list="activeWidgets"
      item-key="widgetName"
      tag="div"
      @end="saveWidgets"
      class="clebex-widget-section"
    >
      <template #item="{element}">
        <widget :data="element"></widget>
      </template>
    </Draggable>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import Widget from "@/components/cockpit/Widget";
import { mapState, mapActions } from "vuex";

export default {
  name: "Cockpit",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Widget,
    Draggable: defineAsyncComponent(() => import("vuedraggable"))
  },
  created() {
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
    this.getWidgets();
  },
  data() {
    return {
      activeWidgets: [],
      inactiveWidgets: []
    };
  },
  computed: {
    ...mapState("cockpit", ["widgets"]),
    dragOptions() {
      return {
        animation: 150,
        delayOnTouchOnly: false,
        ghostClass: "clebex-widget-item-wrapper-ghost",
        chosenClass: "clebex-widget-item-wrapper-chosen",
        dragClass: "clebex-widget-item-wrapper-drag"
      };
    }
  },
  methods: {
    ...mapActions("cockpit", ["getWidgets", "setWidgetsOrder"]),
    setWidgets() {
      this.activeWidgets = [];
      this.inactiveWidgets = [];
      if (this.widgets && this.widgets.length) {
        this.widgets.forEach(element => {
          if (element.is_active === 1) {
            this.activeWidgets.push(element);
          } else {
            this.inactiveWidgets.push(element);
          }
        });
        this.activeWidgets.sort((a, b) => a.order - b.order);
        this.inactiveWidgets.sort((a, b) => a.order - b.order);
      }
    },
    saveWidgets() {
      let ordering = 1;
      const postArray = [];
      const fullArray = this.activeWidgets.concat(this.inactiveWidgets);
      fullArray.forEach(element => {
        postArray.push({
          id: element.id,
          ordering: ordering
        });
        ordering++;
      });
      this.setWidgetsOrder(postArray);
    }
  },
  watch: {
    widgets: {
      deep: true,
      handler() {
        this.setWidgets();
      }
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
