<template>
  <div class="clebex-widget-item-wrapper">
    <div class="clebex-widget-title">{{ data.name }}</div>
    <component
      v-bind:is="widgets[data.type].componentName"
      :data="data"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { widgets } from "@/services/consts/modules/cockpit/widgets";

export default {
  name: "Widget",
  components: {
    VisitorManagement: defineAsyncComponent(() =>
      import(
        "@/components/cockpit/widgets/visitor-management/VisitorManagement"
      )
    ),
    Bookings: defineAsyncComponent(() =>
      import("@/components/cockpit/widgets/bookings/Bookings")
    )
  },
  data() {
    return {
      widgets: widgets
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
